import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, ReplaySubject } from "rxjs";
import { environment } from "environments/environment";
import { Customer } from "../models/customer.type";


@Injectable({
    providedIn: 'root'
})
export class CustomerService
{
    protected apiRoute =  environment.RESOURCE_API;

    public _currentCustomerSubject$ = new BehaviorSubject<Customer>(null);
    private _canSave : ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _sendAddress$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _relod : ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _firstNameDeclarantInfo : BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _lastNameDeclarantInfo : BehaviorSubject<string> = new BehaviorSubject<string>(null);
    dialogPreviewCustomer: Customer = null;
    private filteredCustomerList = new BehaviorSubject<Customer[]>(null);
    filteredCustomerListData = this.filteredCustomerList.asObservable();
    private isLastProspects = new BehaviorSubject<boolean>(null);
    isLastProspectItems = this.isLastProspects.asObservable();

    checkIstLastProspects(isLastProspect: any){
        this.isLastProspects.next(isLastProspect);
    }

    updateFilteredCustomerList(customerList: any) {
        this.filteredCustomerList.next(customerList);
    }

    set canSave(value: boolean)
    {
        this._canSave.next(value);
    }

    get canSave$(): Observable<boolean>
    {
        return this._canSave.asObservable();
    }

    set firstNameDeclarantInfo(value: string)
    {
        this._firstNameDeclarantInfo.next(value);
    }

    get firstNameDeclarantInfo$(): Observable<string>
    {
        return this._firstNameDeclarantInfo.asObservable();
    }

    set lastNameDeclarantInfo(value: string)
    {
        this._lastNameDeclarantInfo.next(value);
    }

    get lastNameDeclarantInfo$(): Observable<string>
    {
        return this._lastNameDeclarantInfo.asObservable();
    }

    set reload(value: boolean)
    {
        this._relod.next(value);
    }

    get reload$(): Observable<boolean>
    {
        return this._relod.asObservable();
    }

    constructor(private _http: HttpClient) {}

    public getCurrentCustomer() {
        return this._currentCustomerSubject$.getValue();
    }

    public setCurrentCustomer(customer: Customer) {
        this._currentCustomerSubject$.next(customer);
    }

    getCustomerById(BenefitId: string): Observable<Customer> {
        const route = this.apiRoute + environment.apiCatalog.customer.subRoutes.getById + BenefitId;
        return this._http.get(route, this.prepareHeader()).pipe(map(res => res as Customer))
    }
    
    getCustomers(companyId: string): Observable<Customer[]> {
        const route = this.apiRoute + environment.apiCatalog.customer.subRoutes.getAllActive.replace('companyId', companyId);
        return this._http.get<Customer[]>(route, this.prepareHeader());
    }

    updateCustomer(customer : Customer): Observable<Customer> {
        const route = this.apiRoute + environment.apiCatalog.customer.subRoutes.update;
        return this._http.put<Customer>(route,customer, this.prepareHeader());
    }

    addCustomer(customer : Customer): Observable<Customer> {
        const route = this.apiRoute + environment.apiCatalog.customer.subRoutes.add;
        return this._http.post<Customer>(route,customer, this.prepareHeader());
    }

    addCustomers(customers : Customer[]): Observable<Customer[]> {
        const route = this.apiRoute + environment.apiCatalog.customer.subRoutes.addRange;
        return this._http.post<Customer[]>(route,customers, this.prepareHeader());
    }

    GetAllCustomers(companyId: string): Observable<Customer[]> {
        const route = this.apiRoute + environment.apiCatalog.customer.subRoutes.getAllCustomers + companyId;
        return this._http.get<Customer[]>(route, this.prepareHeader());
    }

    canAccessCustomer(companyId: string, customerId: string): Observable<boolean> {
        const route = this.apiRoute + environment.apiCatalog.customer.subRoutes.canAccessCustomer + companyId + '/' + customerId;
        return this._http.get<boolean>(route, this.prepareHeader());
    }

    private prepareHeader(): Object {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        return { headers: headers };
    }

    get sendAddress$(): Observable<any>
    {
        return this._sendAddress$.asObservable();
    }

    sendAddress(address: any): void
    {
        this._sendAddress$.next(address);
    }
}
