import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-folder-thumbnail-loader-skeleton',
  templateUrl: './folder-thumbnail-loader-skeleton.component.html'
})
export class FolderThumbnailLoaderSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
