import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';

@Component({
    selector     : 'fuse-confirmation-dialog',
    templateUrl  : './dialog.component.html',
    styles       : [
        `
            .fuse-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-dialog-container {
                    padding: 0 !important;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None
})

export class FuseConfirmationDialogComponent
{
    fieldValue: string = '';
    checkboxValue: boolean = false;
    checkboxVisible: boolean = false;
    submitValue: string = '';
    placeholder: string = '';
    type: string = '';

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig)
    {
        if(data) {
            if(data?.fields?.input?.show){
                this.placeholder = data.fields.input.placeholder;
                this.type = data?.fields?.input?.type;
                this.submitValue = data?.fields?.input?.submitValue;
                this.fieldValue = data?.fields?.input?.value;
            } else if (data?.fields?.textArea?.show) {
                this.placeholder = data.fields.textArea.placeholder;
                this.type = data.fields.textArea.type;
                this.submitValue = data.fields.textArea.submitValue;
                this.fieldValue = data.fields.textArea.value;
            } else if (data?.fields?.checkbox?.show) {
                this.checkboxValue = data.fields.checkbox.value;
            }

        }
    }

    onChange(value){
        this.fieldValue = value.target.value;
    }

    getCheckboxValue(event: any): void {
        this.checkboxValue = event.checked;
    }
}
