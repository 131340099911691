import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorCatchingService {
  protected apiRoute = environment.RESOURCE_API;

  constructor(private _httpClient: HttpClient) {}

  private _notify : ReplaySubject<any> = new ReplaySubject<any>();
    set notify(value: any)
    {
        this._notify.next(value);
    }

    get notify$(): Observable<any>
    {
        return this._notify.asObservable();
    }
}
