import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CustomerService } from './customer.service';
import { decrypt } from 'app/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class CustomerAccessGuard implements CanActivate {

  constructor(
    private _customerService: CustomerService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  canActivate(): Observable<boolean> {
    var customerId = '';
    var id = "";
    const url = window.location.href;
    const segments = url.split('/');
    const idIndex = segments.indexOf('customer-details') + 1;
    if (idIndex !== -1 && idIndex < segments.length) {
       id = segments[idIndex];
       const decryptedGuid = decrypt(id);
       const pattern: RegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

       if(!pattern.test(decryptedGuid)){
        return of(true);
       }
    } else {
      return of(true);
    }

    if (id) {
        const decryptedGuid = decrypt(id);
        customerId = decryptedGuid;
    }
    var company = JSON.parse(localStorage.getItem('currentCompany'));
    return this._customerService.canAccessCustomer(company.id, customerId).pipe(
      map((response: any) => {
        if (response) {
          return true;
        } else {
          return false;
        }
      }),
      switchMap((authenticated: boolean) => {
        if (!authenticated) {
          this.openSnackBar("Vous n'avez pas accès à ce client");
          this.router.navigate(['/prospects']);
          return of(false);
        } else {
          return of(true);
        }
      }),
      catchError((error: any) => {
        console.error('Error occurred while checking authentication:', error);
        this.openSnackBar('An error occurred while checking authentication');
        return of(false);
      })
    );
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'Fermer', {
      duration: 0,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
