import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AddSupportRequestComponent } from './add-support-request.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { QuillModule } from 'ngx-quill';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FileLoaderSkeletonModule } from 'app/core/skeleton/file-loader-skeleton/file-loader-skeleton.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    AddSupportRequestComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatTooltipModule,
    QuillModule.forRoot(),
    MatButtonToggleModule,
    FileLoaderSkeletonModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatButtonModule
  ],
  exports: [
    AddSupportRequestComponent
  ],
  providers: [
    DatePipe,
  ]
})
export class AddSupportRequestModule { }
