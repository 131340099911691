import { Component } from '@angular/core';
import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the roles passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *appHasAnyRole="'ROLE_VALUE'">...</some-element>
 *
 *     <some-element *appHasAnyRole="['ROLE_VALUE_1', 'ROLE_VALUE_2']">...</some-element>
 * ```
 */
@Directive({
  selector: '[appHasAnyRole]'
})
export class HasAnyAppRoleComponentDirective {

  private roles: string[] = [];

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  @Input()
  set appHasAnyRole(value: string | string[]) {
    // check if single role supplied or multiple roles and assign to variable
    this.roles = typeof value === 'string' ? [value] : value;

    // before showing again call to prevent duplicates
    this.updateView();
    
    // Get notified each time authentication state changes
    // replace api with your authentication check    

  }

  private updateView(): void {
    const hasAnyRole = this.checkRole(this.roles); 
    // clear any old reference to prevnt duplication
    this.viewContainerRef.clear();
    // show content if role true, else dont. this is the main logic
    if (hasAnyRole) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
  
  checkRole(roles: string[] | string): boolean {
   // call your api to get user/account DTo for your current logged in user
   // change yourAccount with your variable
   // role/roles with your property having the role value
   
   // if you have single role check, convert it to array
   if (!Array.isArray(roles)) {
      roles = [roles];
   }
   
   const helper = new JwtHelperService();
   let myRawToken = localStorage.getItem("accessToken");

   if (myRawToken != null) {
       const decodedToken = helper.decodeToken(myRawToken);
       var role: string[] | string = decodedToken.role
       if (role == undefined) {
           role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
       }
       if (typeof role === "string") {
           if (role === "SuperAdmin") {
            return false;
           }
       } else {
           if ((role as string[]).some(x => x === "SuperAdmin")) {
            return false;
           }
       }
   }
   return true;
  }

}