import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class NotFoundErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(error: any) {
        const router = this.injector.get(Router);

        if (error.message.includes('Cannot match any routes')) {
            router.navigate(['not-found']);
        } else {
            console.error(error);
        }
    }
}