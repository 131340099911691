import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'environments/environment';
import { StorageItem } from 'app/modules/models/storageItem.type';
import { FolderDoc } from 'app/modules/models/folderDoc.type';

@Injectable({
    providedIn: 'root'
})
export class StorageItemService {
    protected apiRoute = environment.RESOURCE_API;

    constructor(private _httpClient: HttpClient) { }

    getCustomerFolders(customerId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.getCustomerFolders + customerId;
        return this._httpClient.get<any[]>(route, this.prepareHeader());
    }

    uploadFile(file: any, filename: string, customerId: string, companyId: string, folderType: string, folderId: string , devisVersionId: string): Observable<StorageItem> {
        const formData = new FormData();
        formData.append('filename', filename);
        formData.append('file', file);
        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + 
                    environment.apiCatalog.blob.subRoutes.uploadBlob 
                    + "/" + filename + "/" + customerId + "/" + companyId + "/" + folderType + "/" + folderId + "/" + devisVersionId;
        return this._httpClient.post(route, formData, this.prepareHeader()).pipe(map(res => res as StorageItem));
    }

    UploadSignedFile(file: any, filename: string, customerId: string, companyId: string, folderType: string, folderId: string ): Observable<StorageItem> {
        const formData = new FormData();
        formData.append('file', file);
        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + 
                    environment.apiCatalog.blob.subRoutes.uploadSignedBlob 
                    + filename + "/" + customerId + "/" + companyId + "/" + folderType + "/" + folderId ;
        return this._httpClient.post(route, formData, this.prepareHeader()).pipe(map(res => res as StorageItem));
    }

    downloadLastVersionFile(devisVersionId: string): Observable<any> {

        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + environment.apiCatalog.blob.subRoutes.GetLastVersion + devisVersionId;
        return this._httpClient.get(route,this.prepareHeader());
    }

    downloadFile(storageItem: StorageItem): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + environment.apiCatalog.blob.subRoutes.download;
        return this._httpClient.post(route, storageItem, { responseType: 'blob' });
    }

    downloadFolder(storageItem: StorageItem): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + environment.apiCatalog.blob.subRoutes.downloadFolder;
        return this._httpClient.post(route, storageItem, { responseType: 'blob' });
    }

    createFreeFolder(customerId : string, companyId: string, folderNamer: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.createFreeFolder +  customerId + "/" + companyId + "/" + folderNamer;
        return this._httpClient.get(route , this.prepareHeader());
    }

    renameFreeFolder(folderId : string, newName: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.renameFreeFolder + folderId + "/" + newName;
        return this._httpClient.get(route , this.prepareHeader());
    }

    deleteFreeFolder(folderId : string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.deleteFreeFolder + folderId;
        return this._httpClient.get(route , this.prepareHeader());
    }

    getFolderContent(customerId : string, companyId: string, folderType: string, folderId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.getFolderContent + '/' + customerId + "/" + companyId + "/" + folderType+ "/" + folderId;
        return this._httpClient.get(route , this.prepareHeader());
    }

    getFolderContentByVersion(customerId : string, companyId: string, folderType: string, folderId: string, devisVersionId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.getFolderContent + '/' + customerId + "/" + companyId + "/" + folderType+ "/" + folderId + "/" + devisVersionId;
        return this._httpClient.get(route , this.prepareHeader());
    }

    getStorageItemByNodeId(nodeId: string, fileName: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + environment.apiCatalog.blob.subRoutes.getAll + nodeId + "/" + fileName;
        return this._httpClient.get(route, this.prepareHeader());
    }

    addTagToFile(storageItemId: string, tagId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.addTagToFile + storageItemId + "/" + tagId;
        return this._httpClient.get(route, this.prepareHeader());
    }

    addTagToFolder(folderId: string, tagId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.addTagToFolder + folderId + "/" + tagId;
        return this._httpClient.get(route, this.prepareHeader());
    }

    updateFileWithDocNature(storageItemId: string, docNatureId: string, signatureDate: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.updateFileWithDocNature + storageItemId + "?docNatureId="+ (docNatureId ? docNatureId : "") + "&signatureDate=" + (signatureDate ? signatureDate : "");
        return this._httpClient.get(route, this.prepareHeader());
    }

    renameFile(newName: string, storageItemId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.renameBlobAndUpdateDatabase + newName + "/" + storageItemId;
        return this._httpClient.get(route, this.prepareHeader());
    }

    deleteFile(storageItemId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.deleteFileFromDatabase + storageItemId;
        return this._httpClient.get(route, this.prepareHeader());
    }

    getTagsByFolder(folderId: string): Observable<any[]> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.getTagsByFolder + folderId;
        return this._httpClient.get<any[]>(route, this.prepareHeader());
    }

    deleteTagFromFile(storageItemId: string, tagId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.deleteTagFromFile + storageItemId + "/" + tagId;
        return this._httpClient.get(route, this.prepareHeader());
    }

    deleteTagFromFolder(folderId: string, tagId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.deleteTagFromFolder + folderId + "/" + tagId;
        return this._httpClient.get(route, this.prepareHeader());
    }

    getTagsByFile(storageItemId: string): Observable<any[]> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.getTagsByFile + storageItemId;
        return this._httpClient.get<any[]>(route, this.prepareHeader());
    }

    downloadContentFolderZip(folderId: string): Observable<Blob> {
        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + environment.apiCatalog.blob.subRoutes.createZip + folderId;
        return this._httpClient.get(route, { responseType: 'blob'}).pipe(map(res => res as Blob));
    }

    deleteFolder(folderId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.deleteFolderFromDatabase + folderId;
        return this._httpClient.get(route, this.prepareHeader());
    }

    downloadDossierContentZip(customerId: string): Observable<Blob> {
        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + environment.apiCatalog.blob.subRoutes.downloadDossierContentZip + customerId;
        return this._httpClient.get(route, { responseType: 'blob'}).pipe(map(res => res as Blob));
    }

    getIsLastVersionDevisInvoice(entityType: string, entityId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.getIsLastVersionDevisInvoice + entityType + "/" + entityId;
        return this._httpClient.get(route, this.prepareHeader());
    }

    getCompanyFolders(companyId: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.getCompanyFolders + companyId;
        return this._httpClient.get<any[]>(route, this.prepareHeader());
    }

    getPartnerDropFoldersHistory(companyId: string, folderType: string) : Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.storageItem.controllerRoute + environment.apiCatalog.storageItem.subRoutes.getPartnerDropFoldersHistory + companyId + "/" + folderType;
        return this._httpClient.get<any[]>(route, this.prepareHeader());
    }

    getFolderDocuments(folderId: number , folderType: string): Observable<any> {
        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + environment.apiCatalog.blob.subRoutes.loadFolderDocuments + folderId + "/" + folderType;
        return this._httpClient.get<any>(route, this.prepareHeader());
    }

    downloadFolderDoc(file : FolderDoc): Observable<any> {  
        const route = this.apiRoute + environment.apiCatalog.blob.controllerRoute + environment.apiCatalog.blob.subRoutes.downloadFolderFile;
        return this._httpClient.post(route, file,  this.prepareHeader());
    }
    
    private prepareHeader(): Object {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        return { headers: headers };
    }

    
}
