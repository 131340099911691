import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { UserProfile } from 'app/modules/models/profile-management/userProfile.type';
import { RoleService } from 'app/shared/services/role.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, catchError, filter, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { User } from '../user/user.types';
import { PartnersService } from 'app/shared/services/partners.service';

@Injectable()
export class AuthService {
  private authUri: string = environment.IDENTITY_URL;
  private _authenticated: boolean = false;

  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _roleService: RoleService,
    private _partnersService: PartnersService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }
  /**
   * Setter & getter for access token
   */
  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    let queryParam = '?email=' + email;
    return this._httpClient.post(this.authUri + 'api/auth/forgot-password' + queryParam, {});
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post(this.authUri + 'api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { username: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated && this.accessToken) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(this.authUri + 'api/auth/sign-in', credentials).pipe(
      switchMap(async (response: any) => {
        // Store the user on the user service
        let jwtData = response.token.split('.')[1];
        let decodedJwtJsonData = window.atob(jwtData);
        let decodedJwtData = JSON.parse(decodedJwtJsonData);
        // store connected user
        localStorage.setItem(
          'connected_user',
          JSON.stringify({
            id: decodedJwtData.id,
            email: decodedJwtData.Email,
            fullname: decodedJwtData.name,
            role: decodedJwtData.role,
            status: decodedJwtData.status,
            tenantId: decodedJwtData.TenantId,
            tenant: decodedJwtData.Tenant,
            firstname: decodedJwtData.FirstName,
            lastname: decodedJwtData.LastName,
            enabled: decodedJwtData.IsEnabled,
            isOnboarded: decodedJwtData.IsOnboarded,
            onSICA: decodedJwtData.OnSICA,
            onACEP: decodedJwtData.OnACEP,
          })
        );

        // Store the access token & refresh token in the local storage
        this.accessToken = response.token;
        this.refreshToken = response.refreshToken;

        if (decodedJwtData.IsEnabled !== 'True') {
          // || decodedJwtData.OnSICA === 'False') { // enable ACEP users
          this.signOut();
        }

        // Set the authenticated flag to true
        this._authenticated = true;
        this._userService.get(decodedJwtData.Email).subscribe((user: any) => {
          if (user) {
            this._userService.connectedUser = user;
            let currentUser = JSON.parse(localStorage.getItem('connected_user'));
            localStorage.setItem('isPartnerAdmin', (user?.userProfile.name === 'Admin E. Partenaire').toString());
            currentUser.cguAccepted = user.cguAccepted;
            currentUser.cguAcceptedOn = user.cguAcceptedOn;
            currentUser.onboardedOnVersion = user.onboardedOnVersion;
            localStorage.setItem('connected_user', JSON.stringify(currentUser));
            this._userService.createUser = currentUser;
            localStorage.setItem('isPartnerAdmin', (user.userProfile.name === 'Admin E. Partenaire').toString());
            // check if user has userProfile "Partenaire ACEP"
            this.checkUserProfile(user);
            // Check if user has default roles
            this.checkDefautRoles(user.companyId);
          }
        });

      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    if (!this.accessToken) {
      return of(false);
    }
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        switchMap(() => of(true))
      );
    } else {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      if (AuthUtils.isTokenExpired(this.accessToken)) {
      return this._httpClient.post(this.authUri + 'api/auth/refresh-token', {
        accessToken: this.accessToken,
        refreshToken: this.refreshToken,
      }).pipe(
        catchError((error) => {
          console.error('Error during token refresh:', error);
          this.refreshTokenInProgress = false;
          return of(false);
        }),
        switchMap((response: any) => {
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }
          if (response.refreshToken) {
            this.refreshToken = response.refreshToken;
          }
          let jwtData = response.token.split('.')[1];
          let decodedJwtJsonData = window.atob(jwtData);
          let decodedJwtData = JSON.parse(decodedJwtJsonData);
          return this._userService.get(decodedJwtData.Email).pipe(
            tap((user: any) => {
              this._userService.connectedUser = user;
            }),
            switchMap(() => {
              this._authenticated = true;
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(this.accessToken);
              return of(true);
            })
          );
        })
      );
    } else {
      let jwtData = this.accessToken.split('.')[1];
      let decodedJwtJsonData = window.atob(jwtData);
      let decodedJwtData = JSON.parse(decodedJwtJsonData);
      return this._userService.get(decodedJwtData.Email).pipe(
        tap((user: any) => {
          this._userService.connectedUser = user;
        }),
        switchMap(() => {
          this._authenticated = true;
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(this.accessToken);
          return of(true);
        })
      );
    }
  }
  }

  checkUserProfile(user: User) {
    if(user.userProfile && user.userProfile.name === 'Admin E. Partenaire' || user.userProfile.name === 'Partenaire ACEP') {
      // check if local storage contains the selected partener 
      let selectedPartener = localStorage.getItem('selectedPartner');
      if (!selectedPartener && (localStorage.getItem('isPartnerAdmin') === 'false')) {
         this._partnersService.getPartnerByCompanyId(user.companyId).subscribe(partner => {
          if (partner) {
            localStorage.setItem('selectedPartner', JSON.stringify(partner));
          }
         });
      }
    }
  }

  checkDefautRoles(companyId: string) {
    debugger
    this._roleService
      .getRolesByCompanyId(companyId, true)
      .toPromise()
      .then((roles: UserProfile[]) => {
        if (roles.length === 0) {
          let defaultRoles: UserProfile[] = [
            {
              name: 'Propriétaire',
              description: 'Propriétaire: à accès à toutes les pages ainsi que au paramétrage ',
              companyId: companyId,
              isCommon: true,
              permissions: [],
              code: 'owner',
            },
            {
              name: 'Admin',
              description: 'Admin: à accès à toutes les pages ainsi que au paramétrage ',
              companyId: companyId,
              isCommon: true,
              permissions: [],
              code: 'admin',
            },
            {
              name: 'Basique',
              description: 'Basique: à accès à toutes les pages sauf au paramétrage',
              companyId: companyId,
              isCommon: true,
              permissions: [],
              code: 'basic',
            },
          ];
          this._roleService.addRange(defaultRoles).subscribe((roles: UserProfile[]) => {});
        }
      });
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('currentCustomer');
    localStorage.removeItem('connected_user');
    localStorage.removeItem('currentCompany');
    localStorage.removeItem('selectedPartner');
    localStorage.removeItem('error409');
    localStorage.removeItem('isPartnerAdmin');
    localStorage.removeItem('mousePosition');
    
    
    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post(this.authUri + 'api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post(this.authUri + 'api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  isTokenExpired(): boolean {
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return true;
    } else {
      return false;
    }
  }

  hasToken(): boolean {
    if (!this.accessToken) {
      return false;
    } else {
      return true;
    }
  }

  generateToken(email: string): Observable<any> {
    return this._httpClient
      .post(this.authUri + 'api/auth/generate-token/' + `${email}`, email, this.prepareHeader())
      .pipe(map(res => res as any));
  }

  generateRefreshToken(): Observable<any> {
    return this._httpClient
     .post(this.authUri + 'api/auth/refresh-token',  { refreshToken:this.refreshToken , accessToken: this.accessToken }, this.prepareHeader())
     .pipe(map(res => res as any));
  }


  private prepareHeader(): Object {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return { headers: headers };
  }
}
