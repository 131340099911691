import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcepActeur } from 'app/modules/models/acepActeur.type';
import { StorageItem } from 'app/modules/models/storageItem.type';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PartnersService {

    protected apiRoute = environment.RESOURCE_API;

    private _currentPartnerSubject = new BehaviorSubject<AcepActeur | null>(null);

    setCurrentPartner(partner: AcepActeur) {
        this._currentPartnerSubject.next(partner);
    }

    getCurrentPartner() {
        return this._currentPartnerSubject.asObservable();
    }

    constructor(private _httpClient: HttpClient) { }

    notifyDropFolder(dto: any): Observable<AcepActeur> {
        const route = this.apiRoute + environment.apiCatalog.partners.controllerRoute + environment.apiCatalog.partners.subRoutes.notifyDropPartnerFolder ;
        return this._httpClient.post(route, dto, this.prepareHeader()).pipe(map(res => res as AcepActeur))
    }

    getDropHistory(companyId: string): Observable<StorageItem[]> {
        const route = this.apiRoute + environment.apiCatalog.partners.controllerRoute + environment.apiCatalog.partners.subRoutes.getDropHistory + companyId;
        return this._httpClient.get(route, this.prepareHeader()).pipe(map(res => res as StorageItem[]))
    }

    getPartnerByCompanyId(companyId: string): Observable<AcepActeur> {
        const route = this.apiRoute + environment.apiCatalog.partners.controllerRoute  + '/'+ companyId;
        return this._httpClient.get<AcepActeur>(route, this.prepareHeader());
    }

    private prepareHeader(): Object {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        return { headers: headers };
    }
}
