<div class="grid grid-cols-1 w-48 h-48 mb-8 relative  shadow rounded-2xl bg-card"  >
    <div class="aspect-w-9 aspect-h-6 flex items-center justify-center">
        <div class="flex items-center justify-center">
            <!-- Icon -->
            <div class="relative">
                <mat-icon class="icon-size-14 text-hint" [svgIcon]="'heroicons_outline:document'"></mat-icon>
            </div>
        </div>
    </div>
    <ngx-skeleton-loader 
        count="1" 
        appearance="line" 
        animation="pulse" 
        class="w- mx-4"
        [theme]="{ 'height': '20px' }">
    </ngx-skeleton-loader>
</div>