import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { isSuperAdmin } from 'app/shared/utils';
import { Company } from 'app/modules/models/company.type';
import { isPartnerAdmin } from 'app/modules/settings/settings-sidebar.component';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  avatar: string = '';
  user: User;
  selectedPartner: any;
  company: Company;
  isSuperAdmin: boolean = false;
  isPartnerAdmin: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.connectedUser$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        this.user = user;
        this.avatar = 'data:image/png;base64,' + user?.avatar;
        this.selectedPartner = JSON.parse(localStorage.getItem('selectedPartner'));
        this.selectedPartner ?
          this.company = this.selectedPartner :
          this.company = this.user.company;
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
      this.isSuperAdmin = isSuperAdmin();
      this.isPartnerAdmin = isPartnerAdmin();
      this.company = this.user.company;
      localStorage.setItem('currentCompany', JSON.stringify(this.company));
      this.company?.name.length > 20 ? this.company.name = this.company.name.substring(0, 20) + '...' : this.company.name;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    this._userService
      .update({
        ...this.user,
        status,
      })
      .subscribe();
  }

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  goTo(page): void {
    this._router.navigate([page]);
  }
}
