export interface FuseConfirmationConfig {
    
  title?: string;
  message?: string;
  icon?: {
    show?: boolean;
    name?: string;
    color?: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
  };
  fields?: {
    input?: {
      show?: boolean;
      label?: string;
      placeholder?: string,
      type?: string,
      submitValue?: string,
      value?: string,
    };
    textArea?: {
      show?: boolean;
      label?: string;
      placeholder?: string,
      type?: string,
      submitValue?: string,
      value?: string,
      validators?: {
        maxLength?: number; // Adding maxLength validator property
      };
      isValid?: boolean; // New property to track validity
    };
    checkbox?: {
      show?: boolean;
      label?: string;
      value?: boolean;
      submitValue?: boolean;
    };
  };
  actions?: {
    confirm?: {
      show?: boolean;
      label?: string;
      color?: 'primary' | 'accent' | 'warn';
      disabled?: boolean; // New property to disable the action
    };
    secondConfirm?: {
      show?: boolean;
      label?: string;
      color?: 'primary' | 'accent' | 'warn';
    };
    cancel?: {
      show?: boolean;
      label?: string;
    };
  };
  dismissible?: boolean;
}
