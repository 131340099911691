import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileLoaderSkeletonComponent } from './file-loader-skeleton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FileThumbnailLoaderSkeletonComponent } from './file-thumbnail-loader-skeleton.component';
import { MatIconModule } from '@angular/material/icon';
import { FolderThumbnailLoaderSkeletonComponent } from './folder-thumbnail-loader-skeleton.component';



@NgModule({
  declarations: [
    FileLoaderSkeletonComponent,
    FileThumbnailLoaderSkeletonComponent,
    FolderThumbnailLoaderSkeletonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    NgxSkeletonLoaderModule
  ],
  exports: [
    FileLoaderSkeletonComponent,
    FileThumbnailLoaderSkeletonComponent,
    FolderThumbnailLoaderSkeletonComponent
  ]
})
export class FileLoaderSkeletonModule { }
