import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { environment } from 'environments/environment';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { UserService } from './core/user/user.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from './core/auth/auth.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { User } from './core/user/user.types';
import { MessageNotification } from './modules/models/messageNotification.type';
import { isSuperAdmin } from './shared/utils';
import { Router } from '@angular/router';
import { HealthService } from './shared/services/health.service';
import { MessageQueueService } from './shared/services/message-queue.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignedNotificationComponent } from './shared/signed-notification/signed-notification.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  private hubConnectionBuilder!: HubConnection;
  connectedUser!: User;
  mouseX: number;
  mouseY: number;
  /**
   * Constructor
   */
  constructor(
    private ccService: NgcCookieConsentService,
    private _userService: UserService,
    private _authService: AuthService,
    private _healthService: HealthService,
    private _mqService: MessageQueueService, 
    private _fuseConfirmationService: FuseConfirmationService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    let coockieState = localStorage.getItem('accept-coockies');
    this._userService.connectedUser$.subscribe((user: User) => {
      if(user){
        this.connectedUser = user;
        if (!this.connectedUser?.cguAccepted && !this.connectedUser?.isDeleted) {
          this._router.navigate(['/cgu']);
        }
      }
    });

    //this._mqService.ListenToMQNotifications().subscribe(_ => console.info('Listening to MQ'));
    //this.checkHealth();
    if (coockieState != 'true') {
      this.ccService.fadeIn();
    } else {
      this.ccService.fadeOut();
      this.ccService.destroy();
    }
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        localStorage.setItem('accept-coockies', 'true');
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    //this.trackMatomo();

    let urlSignalR = environment.RESOURCE_API + 'notify';
    this.hubConnectionBuilder = new HubConnectionBuilder()
      .withUrl(urlSignalR)
      .withAutomaticReconnect()
      .build();
    this.hubConnectionBuilder
      .start()
      .then(() => console.log('Connection started.......!'))
      .catch(err => console.log('Error while connect with server: ' + err));

    this.hubConnectionBuilder.onclose(() => {
      console.log('Connection closed.......!');
      this.hubConnectionBuilder.start().then(() => console.log('Connection started.......!'));
    });

    this.hubConnectionBuilder.on('BroadcastMessage', (result: MessageNotification) => {
      switch (result.type) {
        case 'RoleUpdate':
          this.handleRoleChange(result);
          break;
        case 'SupportRequest':
          this.handleSupportRequest(result);
          break;
        case 'DeactivateUser':
          this.handleUserDeactivation(result);
          break;
          case 'DeactivateOrganisation':
            this.handleOrganisationDeactivation(result);
            break;
          case 'DocusignESignature':
            this.handleDocusignESignature(result);
            break;
          case 'DropComplete':
            this.DropComplete(result);
            break;
          case 'DeleteUser':
              this.handleUserDeactivation(result);
              break;              
          case 'ExtractComplete':
            this.handleExtractComplete(result);
            break;
          default:
            break;
      }
    });
    
  }
  handleExtractComplete(result: MessageNotification) {
    if (result.content === this.connectedUser?.email) {
      this._snackBar.open('Extraction des dossiers terminée et envoyée à    ' + result.content, null, { 
        duration: 5000,
        panelClass: 'accent-snackbar'
      });
    }
  }
  checkHealth() {
    let unhealthCount = 0;
    setInterval(() => {
      this._healthService.getHealth().subscribe({
      next: (result) => {
        switch (result.status) {
        case 'Unhealthy':
          unhealthCount++;
          if (unhealthCount === 3) {
            this._router.navigate(['/error-page']);
            unhealthCount = 0; // Reset the counter after navigating
          }
          break;
        case 'Healthy':
          unhealthCount = 0; // Reset the counter if the status is healthy
          if (this._router.url === '/error-page') {
            this._router.navigate(['/sign-in']);
          }
          break;
        }
      },
      error: () => {
        this._router.navigate(['/error-page']);
      }
      });
    }, 15000);
  }

  private handleRoleChange(result: MessageNotification): void {
    if (result.content === this.connectedUser?.id || result.content === this.connectedUser?.userProfileId) {
      this.showConfirmation(
        'Changement de rôle',
        'Votre niveau de permission a été modifié. Pour des soucis de sécurité, vous allez être déconnecté pour mettre à jour votre profil.',
        'Se déconnecter',
        () => this.handleSignOutAndReload()
      );
    }
  }

  private handleOrganisationDeactivation(result: MessageNotification): void {
    if (result.content === this.connectedUser?.companyId) {
      this.showConfirmation(
        'Actualisation requise',
        "Des modifications ont été apportées à votre compte. Dans le cadre de ces ajustements et pour garantir la sécurité, une déconnexion est nécessaire pour actualiser vos informations d'accès.",
        'Se déconnecter',
        () => this.handleSignOutAndReload()
      );
    }
  }
  
  private handleSupportRequest(result: MessageNotification): void {
    if (isSuperAdmin()) {
      this.showConfirmation(
        'Nouvelle demande de support',
        'Une nouvelle demande de support a été ajoutée par un utilisateur. Veuillez consulter la liste des demandes de support.',
        'Consulter',
        () => this._router.navigate(['/settings/support-requests'])
      );
    }
  }
  
  private handleUserDeactivation(result: MessageNotification): void {
    if (result.content === this.connectedUser?.email) {
      this.showConfirmation(
        'Actualisation requise',
        "Des modifications ont été apportées à votre compte. Dans le cadre de ces ajustements et pour garantir la sécurité, une déconnexion est nécessaire pour actualiser vos informations d'accès.",
        'Se déconnecter',
        () => this.handleSignOutAndReload()
      );
    }
  }

  private DropComplete(result: MessageNotification): void {
    if (result.companyId === this.connectedUser?.companyId || this.connectedUser?.userProfile?.name === "Admin E. Partenaire") {
      this._snackBar.open('Demande enregistrée avec succès sous la référence ' + result.content, null, {
        duration: 3000,
        panelClass: 'accent-snackbar'
      }).onAction().subscribe(() => {
        // Add your action here
        this._router.navigate(['/partners/drop-list']);
      });
    }
  }

  handleDocusignESignature(result: MessageNotification): void {
    let content = JSON.parse(result.content);
    let customer = result.customer;
    let type = content.Type;
    if (content && result.companyId === this.connectedUser?.companyId 
      && (content.Status === 'envelope-completed' 
      || content.Status === 'envelope-voided' 
      || content.Status === 'envelope-removed'
      || content.Status === 'envelope-discard'
      || content.Status === 'envelope-deleted'
      || content.Status === 'envelope-purge'
      || content.Status === 'envelope-rejected'
      || content.Status === 'envelope-declined')) {
      this._snackBar.openFromComponent(SignedNotificationComponent, {
        data: { 
          content: content,
          customer: customer,
        },
        duration: 10000,
        panelClass: 'esign-snackbar'        
      });
    }

  }
  
  private handleSignOutAndReload(): void {
    // Sign out
    this._authService.signOut();
    // Reload the app
    location.reload();
  }
  
  private showConfirmation(title: string, message: string, confirmLabel: string, confirmAction: () => void): void {
    const dialogRef = this._fuseConfirmationService.open({
      title: title,
      message: message,
      actions: {
        confirm: { label: confirmLabel },
        cancel: { show: false, label: 'Annuler' }
      }
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        confirmAction();
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
  trackMatomo(): void {
    var _paq = ((window as any)._paq = (window as any)._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = '//aceenergie.dyndns.org:8088/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', environment.MATOMO_APP_ID]);
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = u + 'matomo.js';
      s.parentNode.insertBefore(g, s);
    })();
  }
}
