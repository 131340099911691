import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';
import { SharedModule } from 'app/shared/shared.module';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatLineBreakPipe } from '@fuse/pipes/find-by-key/chatLineBreak.pipe';

@NgModule({
    declarations: [
        QuickChatComponent,
        ChatLineBreakPipe
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseDrawerModule,
        FuseScrollbarModule,
        SharedModule,
        MatTooltipModule
    ],
    exports     : [
        QuickChatComponent
    ]
})
export class QuickChatModule
{
}
