import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SupportRequest } from 'app/modules/models/supportRequest.type';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportRequestService {

  protected apiRoute =  environment.RESOURCE_API; 
  private _selectedRequest:  ReplaySubject<SupportRequest> = new ReplaySubject<SupportRequest>(1); 
  private dataSubject = new BehaviorSubject<boolean>(false);
  reload$ = this.dataSubject.asObservable();

  reload(newData: boolean) {
    this.dataSubject.next(newData);
  }

  constructor(private _http: HttpClient) {    
  }

    get selectedRequest$(): Observable<SupportRequest>
    {
        return this._selectedRequest.asObservable();
    }

    set selectedRequest(value: SupportRequest)
    {
        // Store the value
        this._selectedRequest.next(value);
    }

  getAll(): Observable<SupportRequest[]> {
    const route = this.apiRoute + environment.apiCatalog.supportRequest.controllerRoute + environment.apiCatalog.ruleAlert.subRoutes.getAll;
    return this._http.get(route, this.prepareHeader()).pipe(map(res => res as SupportRequest[]))
  }

  getAllByUserId(userId: string): Observable<SupportRequest[]> {
    const route = this.apiRoute + environment.apiCatalog.supportRequest.controllerRoute + environment.apiCatalog.ruleAlert.subRoutes.getAll + '/' + userId;
    return this._http.get(route, this.prepareHeader()).pipe(map(res => res as SupportRequest[]))
  }

  getAllByCompanyId(companyId: string): Observable<SupportRequest[]> {
    const route = this.apiRoute + environment.apiCatalog.supportRequest.controllerRoute + environment.apiCatalog.ruleAlert.subRoutes.getAll + '/' + companyId;
    return this._http.get(route, this.prepareHeader()).pipe(map(res => res as SupportRequest[]))
  }

  addSupportRequest(body: FormData): Observable<SupportRequest> {
    const route = this.apiRoute + environment.apiCatalog.supportRequest.controllerRoute;
    return this._http.post(route, body, this.prepareHeader()).pipe(map(res => res as SupportRequest));
  }

  updateSupportRequest(body: FormData): Observable<SupportRequest> {
    const route = this.apiRoute + environment.apiCatalog.supportRequest.controllerRoute + environment.apiCatalog.supportRequest.subRoutes.update;
    return this._http.post(route, body, this.prepareHeader()).pipe(map(res => res as SupportRequest));
  }

  private prepareHeader(): Object {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return { headers: headers };
  }
   
}