<!-- Button -->
<button id="user-actions-menu"
    mat-stroked-button class="flex rounded-lg hover:bg-accent"
    [matMenuTriggerFor]="userActions">
    <span class="relative p-0.5 bg-accent rounded-full">
        <img
            class="rounded-full w-5 h-5"
            *ngIf="showAvatar && user?.avatar"
            [src]="avatar" />
        <mat-icon
            *ngIf="!showAvatar || !user?.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
            class="absolute bottom-0 right-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user?.avatar,
                          'bg-green-500': user?.status === 'online',
                          'bg-amber-500': user?.status === 'away',
                          'bg-red-500': user?.status === 'busy',
                          'bg-gray-400': user?.status === 'not-visible'}"
        ></span>
    </span>
    <span class="mx-2 text-white text-md">{{selectedPartner ? selectedPartner.nom : company.name}}</span>
    <mat-icon class="text-white icon-size-5" [svgIcon]="'heroicons_outline:chevron-down'"></mat-icon>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span class="font-light text-md">
                {{user?.fullname}}
            </span>    
            <span class="mt-4 text-md font-medium">{{user?.email}}</span>
            
        </span>
    </button>
    
    <mat-divider *appHasAnyRole="'Admin'"  class="my-4"></mat-divider>
        <span class="ml-4">Rôle :</span> <span class="px-2 mx-2 text-white text-md bg-primary rounded-2xl">{{isSuperAdmin ? 'SuperAdmin': user?.userProfile?.name}}</span>
    <mat-divider *appHasAnyRole="'Admin'" ></mat-divider>
    
    <button *ngIf="isPartnerAdmin" mat-menu-item (click)="goTo('partner-selection')" id="user-go-to-partner-selection">
        <mat-icon >multiple_stop</mat-icon>
        <span class="font-semibold">Changer de partenaire</span>
    </button>
    <mat-divider *ngIf="isPartnerAdmin" ></mat-divider>
    
    <button mat-menu-item (click)="goTo('settings/profile')" id="user-go-to-my-profile">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Mon profil</span>
    </button>
    <button *appHasAnyRole="'Admin'" mat-menu-item (click)="goTo('settings/landing')" id="user-go-to-settings">
        <mat-icon [svgIcon]="'heroicons_outline:adjustments'"></mat-icon>
        <span>Paramètres</span>
    </button>
    <!-- do not delete -->
    <!-- <button *appHasAnyRole="'Admin'" 
        mat-menu-item
        [matTooltip]="'Coming soon'"
        >
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Statut</span>
    </button> -->
    <mat-divider  class="my-2"></mat-divider>
    <button
        mat-menu-item id="user-sign-out-btn"
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Se déconnecter</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 bg-green-500 rounded-full"></span>
        <span>Online</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 bg-red-500 rounded-full"></span>
        <span>Busy</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 bg-gray-400 rounded-full"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
