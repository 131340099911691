<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->

<fuse-vertical-navigation class="dark bg-primary print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation?.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-32 px-8 pt-6">
            <img class="w-24 mx-auto cursor-pointer" src="assets/images/logo/Logo-SICA-color-light.png" 
                alt="Logo Sica" (click)="goTo('dashboard')" id="header-app-logo">
        </div>
    </ng-container>
</fuse-vertical-navigation>


<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 bg-sable">
    <div *ngIf="isCloned ==='true'" class="relative flex w-full h-10 flex-0 z-49 min-h-10 bg-warn text-sable">
        <div class="flex items-center m-auto text-center">
            <div class="w-8 mt-[3px]">
                <mat-icon class="text-primary" svgIcon="mat_solid:info"></mat-icon>
            </div>
            <div class="text-primary">Vous êtes connecté en tant que <b>{{ConnectedUserEmail}}</b></div>
        </div>
    </div>
    <fuse-alert *ngIf="showErrors && errorMessage" class="rounded-none" [type]="'warn'" [appearance]="'border'"
        [dismissible]="true" [dismissed]="false">
        <span fuseAlertTitle>Error alert</span>
        {{errorMessage?.errorMessage}}
        <p class="font-semibold">Details: {{errorMessage?.error?.message}}</p>
    </fuse-alert>
    <!-- Header -->
    <div class="relative flex items-center w-full h-16 px-4 shadow header bg-primary flex-0 sm:h-20 md:px-6 z-49 dark:shadow-none dark:border-b dark:bg-transparent print:hidden"
        *ngIf="showMenu">
        <ng-container *ngIf="!isScreenSmall" >
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img alt="logo" class="w-16 dark:hidden cursor-pointer" src="assets/images/logo/Logo-SICA-color-light.png" (click)="goTo('dashboard')">
                    <img alt="logo" class="hidden w-16 dark:flex cursor-pointer" src="assets/images/logo/Logo-SICA-color-light.png" (click)="goTo('dashboard')">
                </div>
                <img alt="logo" class="flex w-8 lg:hidden cursor-pointer" src="assets/images/logo/Icon-SICA-color-light.png" (click)="goTo('dashboard')">
            </div>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'" 
                [navigation]="navigation?.horizontal"></fuse-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')" id="header-small-screnn-menu-btn">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center px-3 ml-auto space-x-0.5 sm:space-x-2">
            <button  class="rounded-full text-white text-sm mr-2 hidden" [matTooltip]="'Envoyer une demande au support Sica'" mat-stroked-button (click)="open()" id="header-contact-support-btn">
                <mat-icon class="text-secondary pr-2" svgIcon="feather:help-circle"></mat-icon>
                <span class="text-md text-secondary">Contacter le support</span>
            </button>
            
            
            
            <languages class="hidden"></languages>
            <ng-container *ngIf="!isSuperAdmin">
                <button mat-mini-fab class="text-white add-customer-button" id="header-new-customer-btn"
                    (click)="createCustomer()" [matTooltip]="'Ajouter un nouveau client'"
                    *ngIf="onSICA">
                    <mat-icon class="text-white icon-size-5" svgIcon="heroicons_solid:user-add"></mat-icon>
                    
                </button>
                <button mat-mini-fab class="text-white add-customer-button" id="header-new-customer-btn"
                    (click)="makeNewDrop()" [matTooltip]="'Déposer un dossier de financement'" *ngIf="!onSICA">
                    <mat-icon class="text-white icon-size-5">download</mat-icon>
                </button>
                <button mat-icon-button class="ml-2" [matTooltip]="'Contacter le support'" (click)="open()">
                    <mat-icon class="text-slate-300 hover:text-moutarde" svgIcon="mat_outline:contact_support"></mat-icon>
                </button>                
            </ng-container>

            

            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- TODO : ADD SHORTCUTS -->
            <shortcuts class="hidden"></shortcuts>
            <messages class="hidden"></messages>
            <notifications class="hidden"></notifications>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>

        <fuse-drawer [fixed]="true" [mode]="'over'" [name]="'rightDrawer'" [position]="'right'"
            [transparentOverlay]="false" (openedChanged)="drawerOpenedChanged($event)" #supportDrawer>
            <div class="flex flex-col h-full bg-sable">
                <div class="flex flex-row w-full p-2 font-medium border-b border-solid gray-bottom-border">
                    <h3 class="font-['Bitter'] text-[24px] font-semibold leading-[32px] w-120 mr-8 ml-2 mt-4">{{title}}
                    </h3>
                    <div class="mt-4 ml-6">
                        <button class="order-first mb-2 -ml-3 sm:ml-0 sm:mb-0 sm:order-last" mat-icon-button
                        (click)="supportDrawer.close()">
                            <mat-icon svgIcon="mat_outline:close"></mat-icon>
                        </button>
                    </div>
                </div>
                <!-- body section-->
                <div class="flex-auto overflow-auto">
                    <!-- drawer edit zone -->
                    <app-add-support-request *ngIf="supportDrawer.opened" #addSupportRequest
                        (closeDrawer)="closeDrawer($event)"></app-add-support-request>
                </div>

                <!-- drawer action zone -->
                <!--    <div class="flex flex-row border-t border-solid gray-bottom-border">
            <div class="grid w-full grid-cols-1 gap-8 p-3 sm:grid-cols-2 lg:grid-cols-2">
              <button mat-stroked-button (click)="toggleDrawer()">Annuler</button>
              <button mat-flat-button class="text-white bg-accent"
                (click)="sendDemand()">Envoyer</button>
            </div>
          </div> -->
            </div>
        </fuse-drawer>
    </div>

    <!-- Footer -->
    <div
        class="relative flex items-center hidden w-full px-4 border-t flex-0 h-14 sm:h-20 md:px-6 z-49 bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->