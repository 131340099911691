import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from '../../../../core/user/user.service';
import { environment } from 'environments/environment';
import { ErrorCatchingService } from 'app/shared/services/error-catching.service';
import { AddSupportRequestComponent } from 'app/modules/settings/support/add-support-request/add-support-request.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'app/core/user/user.types';
import { Customer } from 'app/modules/models/customer.type';
import { ActivatedRoute, Router } from '@angular/router';
import { IStatusPanelParams } from 'ag-grid-community';
import { isSuperAdmin } from 'app/shared/utils';
import { FuseDrawerComponent } from '@fuse/components/drawer';

@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    styleUrls: ['./modern.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
    @ViewChild('supportDrawer') supportDrawer!: FuseDrawerComponent;
    isSuperAdmin =  isSuperAdmin();
    isScreenSmall: boolean;
    showMenu: boolean = true;
    showErrors: boolean = false;
    errorMessage: any = null;
    navigation: Navigation;
    isCloned: string;
    ConnectedUserEmail: string;
    connectedUser: User;
    title: string = 'Créer une demande de support';
    onSICA: boolean = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('addSupportRequest', { static: false })
    public addSupportRequest!: AddSupportRequestComponent;

    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _userService: UserService,
        private _errorCatching: ErrorCatchingService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _route: ActivatedRoute
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.showErrors = environment.showErrors;
        this.isCloned = localStorage.getItem("isCloned");
        this.ConnectedUserEmail = JSON.parse(localStorage.getItem("connected_user")).email;
        this._userService.connectedUser$.subscribe((user: User) => {
            this.connectedUser = user;
        });
        //subscribe to error catching service
        this._errorCatching.notify$.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((errorMessage: Navigation) => {
                this.errorMessage = errorMessage;
            });

        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        // Subscribe to onboarding
        this._userService.isOnboarding$.pipe(takeUntil(this._unsubscribeAll)).subscribe(isOnbording => {
            this.showMenu = !isOnbording;
        });
        this.onSICA = JSON.parse(localStorage.getItem('connected_user')).onSICA === 'True';
    }

    makeNewDrop(): void {
        this._router.navigate(['/partners/drop']);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
    toggleDrawer(): void {
        this.supportDrawer.close();
    }
    drawerOpenedChanged(event) {
    }

    open(): void {
        this.supportDrawer.open();
    }

    closeDrawer(event) {
        if (event) {
            this.supportDrawer.close();
        }
    }

    createCustomer(): void {
        //test if we are indeed in the same page       

        if (!this._router.url.includes("/prospects/customer-details") ||
            this._router.url.includes("devis") || 
            this._router.url.includes("invoice")) {
            let customer = {} as Customer;
            customer.id = 'new'
            localStorage.setItem('currentCustomer', JSON.stringify(customer));
            this._router.navigate(['/prospects/customer-details']);
        } else {
            let customer = {} as Customer;
            customer.id = 'new'
            localStorage.setItem('currentCustomer', JSON.stringify(customer));
            this.navigateToCustomerDetails();
        }
    }

    navigateToCustomerDetails() {
        if( this._router.url == "/prospects/customer-details") {
            this._router.navigate(['/prospects/customer-details', "reload"]);
        }else {
            this._router.navigate(['/prospects/customer-details']);
        }
 

    }

    goTo(page): void {
        this._router.navigate([page]);
    }
}
