/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { isOnlyACEPUser } from 'app/shared/utils';
import { NavigationMockApi } from './api';


// The small one
export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Accueil',
        type : 'basic',
        link : '/dashboard',
        roles: 'Admin',
        hidden: _ => isOnlyACEPUser()
    },
    {
        id   : 'prospects',
        title: 'Clients',
        type : 'basic',
        link : '/prospects',
        roles: 'Admin',
        hidden: _ => isOnlyACEPUser()
    },
    {
        id   : 'planning',
        title: 'Planning',
        type : 'basic',
        link : '/rdvs',
        roles: 'Admin',
        hidden: _ => isOnlyACEPUser()
    },
    {
        id   : 'map',
        title: 'Tournée',
        type : 'basic',
        link : '/map',
        roles: 'Admin',
        hidden: _ => isOnlyACEPUser()
    },
    // {
    //     id   : 'partners',
    //     title: 'Financement',
    //     type : 'group',
    //     hidden: _ => isOnlyACEPUser(),     
    //     children : [ 
    //         {
    //             id   : '1',
    //             title: 'Tableau de bord',
    //             type : 'basic',                
    //             link : '/partners/dashboard',
    //             function: _ => executeActionBeforeLeavingCustomerDetails('/partners/dashboard'),
    //             hidden: _ => !hasPermission('partners/dashboard')
    //         },            
    //         {
    //             id   : '2',
    //             title: 'Dossier',
    //             type : 'basic',
    //             link : '/partners/drop-list',
    //             function: _ => executeActionBeforeLeavingCustomerDetails('/partners/drop-list'),
    //             hidden: _ => !hasPermission('partners/drop-list')
    //         }, 
    //         {
    //             id   : '3',
    //             title: 'Facturation',
    //             type : 'basic',
    //             link : '/partners/invoices',
    //             function: _ => executeActionBeforeLeavingCustomerDetails('/partners/invoices'),
    //             hidden: _ => !hasPermission('partners/invoices')
    //         },
    //         {
    //             id   : '4',
    //             title: 'Contrat',
    //             type : 'basic',                
    //             link : '/partners/drop',
    //             function: _ => executeActionBeforeLeavingCustomerDetails('/partners/drop'),
    //             hidden: _ => !hasPermission('partners/drop')
    //         },
    //     ]
    // },
    {
        id   : 'partners_dashboard',
        title: 'Accueil',
        type : 'basic',
        link : '/partners/dashboard',
        roles: 'Admin'
    },
    {
        id   : 'partners_foldrs',
        title: 'Dossiers',
        type : 'basic',
        link : '/partners/drop-list',
        roles: 'Admin'
    },
    {
        id   : 'partners_invoices',
        title: 'Facturation',
        type : 'basic',
        link : '/partners/invoices',
        roles: 'Admin'
    },
    {
        id   : 'partners_contracts',
        title: 'Contrats',
        type : 'basic',
        link : '/partners/contracts',
        roles: 'Admin'
    },
    {
        id   : 'settings',
        title: 'Paramètres',
        type : 'basic',
        link : '/settings/landing',
        roles: 'Admin',
        // hidden: _ => isOnlyACEPUser() && !isAdminPartner() : setting is accessible to everyone, restriction are made on profile settings
    }
];

export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Accueil',
        type : 'basic',
        link : '/dashboard',
        roles: 'Admin',
        hidden: _ => isOnlyACEPUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/dashboard')
    },
    {
        id   : 'prospects',
        title: 'Clients',
        type : 'basic',
        link : '/prospects',
        roles: 'Admin',
        hidden: _ => isOnlyACEPUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/prospects')
    },
    {
        id   : 'planning',
        title: 'Planning',
        type : 'basic',
        link : '/rdvs',
        roles: 'Admin',
        hidden: _ => isOnlyACEPUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/rdvs')
    },
    {
        id   : 'map',
        title: 'Tournée',
        type : 'basic',
        link : '/map',
        roles: 'Admin',
        hidden: _ => isOnlyACEPUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/map')
    },    
    {
        id   : 'partners_dashboard',
        title: 'Accueil',
        type : 'basic',
        link : '/partners/dashboard',
        roles: 'Admin',
        hidden: _ => !isOnlyACEPUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/partners/dashboard')
    },
    {
        id   : 'partners',
        title: 'Financement',
        type : 'group',
        link : '/partners',
        roles: "Admin",
        hidden: _ => isOnlyACEPUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/partners'),
    },
    {
        id   : 'partners_foldrs',
        title: 'Dossiers',
        type : 'basic',
        link : '/partners/drop-list',
        roles: 'Admin',
        hidden: _ => !isOnlyACEPUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/partners/drop-list')
    },
    {
        id   : 'partners_invoices',
        title: 'Facturation',
        type : 'basic',
        link : '/partners/invoices',
        roles: 'Admin',
        hidden: _ => !isOnlyACEPUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/partners/invoices')
    },
    {
        id   : 'partners_contracts',
        title: 'Contrats',
        type : 'basic',
        link : '/partners/contracts',
        roles: 'Admin',
        hidden: _ => !isOnlyACEPUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/partners/contracts')
    },
    {
        id   : 'settings',
        title: 'Paramètres',
        type : 'basic',
        link : '/settings/landing',
        roles: 'Admin',
        //hidden: _ => isOnlyACEPUser() && !isAdminPartner()
    }
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'Accueil',
        title: 'Accueil',
        type : 'basic',
        //icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];

export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'Accueil',
        title: 'Accueil',
        type : 'basic',
        //icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];

function executeActionBeforeLeavingCustomerDetails(uri): void {
    NavigationMockApi.executeActionBeforeLeavingCustomerDetails(uri);       
}

function hasPermission(redirectURL : string): boolean {
    if (NavigationMockApi.userPermissions?.length > 0) {
        let filtredPermissions = NavigationMockApi.userPermissions.filter(x => x.pageRoute == redirectURL);
        if (filtredPermissions.length > 0) {
            return true;
        } else {
            return false;
        }
        } else {     
        return false;
        }
}

function isAdminPartner() {
    return NavigationMockApi.userProfile.name == 'Admin E. Partenaire';
}
