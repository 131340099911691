import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { UserPermission } from '../models/profile-management/userPermission.type';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';


@Component({
  selector: 'settings-sidebar',
  template: `
    <div class="pt-10">
      <!-- Add any extra content that might be supplied with the component -->
      <div class="extra-content">
        <ng-content></ng-content>
      </div>

      <!-- Fixed demo sidebar -->
      <div class="flex">
      <div class="rounded-r-lg cursor-pointer bg-accent">
            <button  mat-icon-button (click)="toggleSideDrawer()" [matTooltip]="'Masquer le menu'">
                <mat-icon class="text-white" >menu</mat-icon>
          </button>
      </div>
      <div class="mx-6 text-3xl font-bold tracking-tighter text-white">Administration</div>        
      </div>
  
      <fuse-vertical-navigation
        [appearance]="'default'"
        [navigation]="menuData"
        [inner]="true"
        [mode]="'side'"
        [name]="'demo-sidebar-navigation'"
        [opened]="true"
        class="text-white"
      ></fuse-vertical-navigation>

      <!-- Storage -->
      <div class="mx-6 mt-2 hidden">
        <div class="flex items-center">
          <mat-icon
            class="mr-2 icon-size-5 text-accent"
            [svgIcon]="'heroicons_solid:database'"
          ></mat-icon>
          <div class="text-lg font-semibold text-accent">Storage</div>
        </div>
        <div class="flex flex-col flex-auto mt-4">
          <span class="text-sm leading-none mb-3 text-white">19.9 GB of 100 GB used</span>
          <mat-progress-bar
            [mode]="'determinate'"
            [color]="'warn'"
            [value]="19.9"
          ></mat-progress-bar>
        </div>
      </div>

    </div>
  `,
  styles: [
    `
      demo-sidebar fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
        box-shadow: none !important;
      }

      fuse-vertical-navigation-group-item
        > .fuse-vertical-navigation-item-wrapper
        .fuse-vertical-navigation-item
        .fuse-vertical-navigation-item-title-wrapper
        .fuse-vertical-navigation-item-title {
        color: #469278 !important;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SettingSidebarComponent implements OnInit {

  companyId = JSON.parse(localStorage.getItem('connected_user')).companyId;
  userCount = '0';
  menuData: FuseNavigationItem[];
  user: User;
  userPermissions: UserPermission[] = [];
  @Output() 
  drawerChanged = new EventEmitter<boolean>();
  /**
   * Constructor
   */
  constructor(
    private _userService: UserService) {
  }

  ngOnInit(): void {
    this._userService.connectedUser$.subscribe(res => {  
      this.user = res;
      if(res) {
        localStorage.setItem('isPartnerAdmin', (res.userProfile.name === 'Admin E. Partenaire').toString());
        this.userPermissions = res.userProfile?.permissions;
        this.getUserCount(res.companyId);
      }
      
    });
    this._userService.reload$.subscribe(res => this.getUserCount(this.user.companyId));
  }

  getUserCount(companyId: string): void {

    if (isSuperAdmin()) {
      this._userService.getCountAll().subscribe(result => {
        this.fillSideBar(result);
      });
    } else {
      this._userService.getCountByCompanyId(companyId).subscribe(result => {
        this.fillSideBar(result);
      });
    }

  }
  
  hasPermission(redirectURL: string): boolean {
    if (this.userPermissions?.length > 0) {
      let filtredPermissions = this.userPermissions.filter(x => x.pageRoute == redirectURL);
      if (filtredPermissions.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {     
      return false;
    }
  }

  fillSideBar(result) {
    this.userCount = result?.toString();

    this.menuData = [
      {
        title: 'Mon profil',
        type: 'group',
        active: true,
        children: [
          {
            title: 'Informations personnelles',
            type: 'basic',
            icon: 'heroicons_outline:user-circle',
            link: 'profile',
          },
        ],
      },
      {
        title: 'Général',
        subtitle: "Identité, équipe et droit d'accès",
        type: 'group',
        active: true,
        hidden: (item) => item.children.every((child) => typeof child.hidden === 'function' ? child.hidden(child) : true),
        children: [
          {
            title: "Coordonnées de l'entreprise",
            type: 'basic',
            icon: 'heroicons_outline:office-building',
            link: 'company',
            hidden: (item) => isSuperAdmin() ? true : !this.hasPermission('/settings/company'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/company') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: "Qualifications",
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: 'qualifications',
            hidden: (item) =>  isSuperAdmin() ? true : !this.hasPermission('/settings/qualifications'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/qualifications') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Prix CEE bénéficiaire',
            type: 'basic',
            icon: 'mat_outline:payments',
            link: 'deals',
            hidden: (item) => isSuperAdmin() ? true : !this.hasPermission('/settings/deals'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/deals') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Organisations',
            type: 'basic',
            icon: 'heroicons_outline:office-building',
            link: 'companies',
            hidden: (item) => !isSuperAdmin(),
          },
          {
            title: "Règles de validation",
            type: 'basic',
            icon: 'mat_solid:playlist_add_check',
            link: 'rules-list',
            hidden: (item) => !isSuperAdmin()
          },
        ],
      },
      {
        title: ' Gestion commerciale',
        type: 'group',
        active: true,
        hidden: (item) => item.children.every((child) => typeof child.hidden === 'function' ? child.hidden(child) : true),
        children: [
          {
            title: "Gestion des équipes",
            type: 'basic',
            icon: 'heroicons_outline:office-building',
            link: 'teams',
            hidden: (item) =>  isSuperAdmin() ? true : !this.hasPermission('/settings/teams'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/teams') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface' 
          },
          {
            title: "Types d'évènements",
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: 'events',
            hidden: (item) =>  isSuperAdmin() ? false :!this.hasPermission('/settings/events'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/events') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface' 
          },
          {
            title: 'Statuts des évènements',
            type: 'basic',
            icon: 'mat_outline:edit_attributes',
            link: 'event-status',
            hidden: (item) => isSuperAdmin() ? false : !this.hasPermission('/settings/event-status'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/event-status') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },    
          {
            title: "Gestion des Ressources",
            type: 'basic',
            icon: 'local_shipping',
            link: 'resources',
            hidden: (item) =>  isSuperAdmin() ? true : !this.hasPermission('/settings/resources'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/resources') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          }
        ],
       },
      {
        title: "Droits d'accès",
        type: 'group',
        hidden: (item) => item.children.every((child) => typeof child.hidden === 'function' ? child.hidden(child) : true),
        children: [
          {
            title: 'Utilisateurs',
            type: 'basic',
            icon: 'heroicons_outline:users',
            badge: {
              title: this.userCount,
              classes: 'px-2 bg-accent text-on-primary rounded-full',
            },
            link: 'users',
            hidden: (item) => isSuperAdmin() ? false : !this.hasPermission('/settings/users'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/users') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Rôles',
            type: 'basic',
            icon: 'mat_outline:admin_panel_settings',
            link: 'roles',
            hidden: (item) => isSuperAdmin() ? false :  !this.hasPermission('/settings/roles'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/roles') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Droits d\'accès',
            type: 'basic',
            icon: 'mat_outline:vpn_key',
            link: 'access-rights',
            hidden: (item) => !isSuperAdmin(),
          },
        ],
      },
      {
        title: 'Paramètres',
        type: 'group',
        hidden: (item) => item.children.every((child) => typeof child.hidden === 'function' ? child.hidden(child) : true),
        children: [
          {
            title: 'Travaux',
            type: 'basic',
            icon: 'heroicons_solid:screw',
            link: 'benefits',
            hidden: (item) => isSuperAdmin() ? false : !this.hasPermission('/settings/benefits'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/benefits') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Catalogue produits',
            type: 'basic',
            icon: 'mat_outline:menu_book',
            link: 'products',
            hidden: (item) => isSuperAdmin() ? true : !this.hasPermission('/settings/products'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/products') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Sous-traitants',
            type: 'basic',
            icon: 'mat_outline:engineering',
            link: 'subcontractors',
            hidden: (item) => isSuperAdmin() ? true : !this.hasPermission('/settings/subcontractors'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/subcontractors') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Délégataires',
            type: 'basic',
            icon: 'mat_outline:real_estate_agent',
            link: 'delegates',
            hidden: (item) => isSuperAdmin() ? false : !this.hasPermission('/settings/delegates'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/delegates') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Mandataires MPR',
            type: 'basic',
            icon: 'mat_outline:home_repair_service',
            link: 'mandates',
            hidden: (item) => isSuperAdmin() ? false : !this.hasPermission('/settings/mandates'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/mandates') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: "Bureaux d'étude",
            type: 'basic',
            icon: 'mat_outline:business',
            link: 'design-office',
            hidden: (item) => isSuperAdmin() ? true : !this.hasPermission('/settings/design-office'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/design-office') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Devis & Facture',
            type: 'basic',
            icon: 'mat_outline:article',
            link: 'devis',
            hidden: (item) => isSuperAdmin() ? false : !this.hasPermission('/settings/devis'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/devis') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Collecte des déchets',
            type: 'basic',
            icon: 'mat_outline:local_shipping',
            link: 'garbage-collector',
            hidden: (item) =>  isSuperAdmin() ? true : !this.hasPermission('/settings/garbage-collector'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/garbage-collector') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Nature de documents & étiquettes',
            type: 'basic',
            icon: 'heroicons_outline:bookmark',
            link: 'tags',
            hidden: (item) =>  isSuperAdmin() ? false : !this.hasPermission('/settings/tags'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/tags') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Statuts des bénéficiaires',
            type: 'basic',
            icon: 'mat_outline:edit_attributes',
            link: 'beneficiary-status',
            hidden: (item) => isSuperAdmin() ? false : !this.hasPermission('/settings/beneficiary-status'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/beneficiary-status') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: 'Configuration de la base de données',
            type: 'basic',
            icon: 'mat_outline:settings',
            link: 'configure-db',
            hidden: (item) => !isSuperAdmin() || environment.environmentName === '',
          }
        ]
      },
      {
        title: 'Support',
        type: 'group',
        active: true,
        hidden: (item) => item.children.every((child) => typeof child.hidden === 'function' ? child.hidden(child) : true),
        children: [
          {
            title: "Suivi des demandes",
            type: 'basic',
            icon: 'mat_outline:contact_support',
            link: 'support-requests',
            hidden: (item) =>  isSuperAdmin() ? false : !this.hasPermission('/settings/support-requests'),
            tooltip: isSuperAdmin() || this.hasPermission('/settings/support-requests') ? '' : 'Votre niveau de permission ne permet pas l\'accès à cette interface'
          },
          {
            title: "FAQ",
            type: 'basic',
            icon: 'mat_outline:quiz',
            link: 'faq',
          },
          {
            title: "Boutons d'information",
            type: 'basic',
            icon: 'mat_outline:lightbulb',
            link: 'info',
            hidden : (item) =>  !isPartnerAdmin() && !isSuperAdmin()  
          },
          {
            title: "Messages d'information",
            type: 'basic',
            icon: 'mat_outline:message',
            link: 'section-message',
            hidden : (item) =>   !isPartnerAdmin() && !isSuperAdmin() 
          },
          {
            title: "Messages partenaires",
            type: 'basic',
            icon: 'mat_outline:campaign',
            link: 'partner-message',
            hidden : (item) =>   !isPartnerAdmin() && !isSuperAdmin()
          }
        ]
      },
    ];
  }

  toggleSideDrawer() {
    this.drawerChanged.emit(false);
  }

}

export function isSuperAdmin() {
  const helper = new JwtHelperService();
  let myRawToken = localStorage.getItem("accessToken");

  if (myRawToken != null) {
    const decodedToken = helper.decodeToken(myRawToken);
    var role: string[] | string = decodedToken.role
    if (role == undefined) {
      role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    }
    if (typeof role === "string") {
      if (role === "SuperAdmin") {
        return true;
      }
    } else {
      if ((role as string[]).some(x => x === "SuperAdmin")) {
        return true;
      }
    }
  }
  return false;
}

export function isPartnerAdmin() {
  return localStorage.getItem("isPartnerAdmin") === 'true';  
}
