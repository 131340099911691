import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { JwtHelperService } from '@auth0/angular-jwt';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';
import { UserService } from 'app/core/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi {

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;
    private horizontalNavigationToReturn: FuseNavigationItem[] = [];
    static userPermissions = [];
    static userProfile: any;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService,
        private _userService: UserService
    ) {
        // Register Mock API handlers
        this.registerHandlers();
        this.getUserPermissions();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === compactNavItem.id) {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === futuristicNavItem.id) {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });
                this.horizontalNavigationToReturn = [];
                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {

                        const helper = new JwtHelperService();
                        let myRawToken = localStorage.getItem("accessToken");

                        if (myRawToken != null) {
                            const decodedToken = helper.decodeToken(myRawToken);
                            var role: string[] | string = decodedToken.role
                            if (role == undefined) {
                                role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
                            }
                            if (typeof role === "string") {
                                if (defaultNavItem.id === horizontalNavItem.id && (role === horizontalNavItem.roles)) {
                                    this.horizontalNavigationToReturn.push(horizontalNavItem);
                                    horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                                }
                            } else {
                                if (defaultNavItem.id === horizontalNavItem.id && (role as string[]).some(x => x === horizontalNavItem.roles)) {
                                    this.horizontalNavigationToReturn.push(horizontalNavItem);
                                    horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                                }
                            }
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact: cloneDeep(this._compactNavigation),
                        default: cloneDeep(this._defaultNavigation),
                        futuristic: cloneDeep(this._futuristicNavigation),
                        horizontal: cloneDeep(this.horizontalNavigationToReturn)
                    }
                ];
            });
    }
    
    getUserPermissions() {
        this._userService.connectedUser$.subscribe(res => {  
            if(res) {
              NavigationMockApi.userProfile = res.userProfile;
              NavigationMockApi.userPermissions = res.userProfile?.permissions;
            }
        });
    }

    static executeActionBeforeLeavingCustomerDetails(uri) {
        // const currentRoute = NavigationService.getInstance().getCurrentRoute();
        // if(!currentRoute.includes("/prospects/customer-details")) {
        //     NavigationService.getInstance().navigateToUri(uri);
        // } else {
        //     NavigationService.getInstance().addProspect = {type: 'create', uri: uri, value: true};
        // }
    }
}
