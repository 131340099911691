import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { encrypt } from '../utils';
import { Customer } from 'app/modules/models/customer.type';

@Component({
  selector: 'app-devis-notification',
  templateUrl: './signed-notification.component.html',
  styleUrls: ['./signed-notification.component.scss'],
})
export class SignedNotificationComponent implements OnInit {

  type: string = '';
  customer: Customer = {} as Customer;
  constructor(private _snackBar: MatSnackBar, @Inject(MAT_SNACK_BAR_DATA)public data: any , private _router: Router) {}

  ngOnInit(): void {
    this.type = this.data.content.Type;
    this.customer = this.data.customer;
  }

  goToSignedDevis() {
    if(this.data){
    let url = '/prospects/customer-details/devis' + '/' + encrypt(this.data.content.CustomerId);
    this._router.navigate([url]);
    this.hideSnackBar();
    } else {
      this.hideSnackBar();
    }
  }

  goToSignedDocument(){
    if(this.data){
    let url = '/prospects/customer-details' + '/' + encrypt(this.data.content.CustomerId);
    this._router.navigate([url]);
    this.hideSnackBar();
    } else {
      this.hideSnackBar();
    }
  }

  hideSnackBar(): void {
    this._snackBar.dismiss();
  }
}
