import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    /**
     * Constructor
     */
    constructor(private _authService: AuthService)
    {
        this.isRefreshing = false;
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();
        

        // If the access token is valid, attach it to the headers
        if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken),
            });
        } 

        // Handle the request
        return next.handle(newReq).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401 && !this.isRefreshing) {
                        return this.handle401Error(newReq, next);
                    } else if (error.status === 406) {
                        // Handle "406 Not Acceptable"
                        localStorage.setItem('error406', 'true');
                        setTimeout(() => {
                            this._authService.signOut();
                            location.reload();
                        }, 300);
                    }
                }

                return throwError(() => error);
            })
        );
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this._authService.generateRefreshToken().pipe(
                switchMap((newTokens) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(newTokens.accessToken);
                    this._authService.accessToken = newTokens.accessToken;
                    this._authService.refreshToken = newTokens.refreshToken;

                    // Retry the failed request with the new access token
                    return next.handle(
                        req.clone({
                            headers: req.headers.set('Authorization', 'Bearer ' + newTokens.accessToken),
                        })
                    );
                }),
                catchError((err) => {
                    this.isRefreshing = false;
                    this._authService.signOut();
                    return throwError(() => err);
                })
            );
        } else {
            // Wait for the token refresh process to complete
            return this.refreshTokenSubject.pipe(
                filter((token) => token !== null),
                take(1),
                switchMap((token) =>
                    next.handle(
                        req.clone({
                            headers: req.headers.set('Authorization', 'Bearer ' + token!),
                        })
                    )
                )
            );
        }
    }
}
