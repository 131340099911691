import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatlinebreak'
})
export class ChatLineBreakPipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.replace(/\r?\n/g, '<br>') : '';
  }
}
