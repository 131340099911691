<ng-container class="flex flex-row justify-between">
    <p class="text-white" *ngIf="this.type === 'DEVIS'">
        Le statut du devis du client <b>{{customer.fullName}}</b> vient d'évoluer, cliquer 
        <a class="text-white p-1 hover:bg-accent border-2 border-accent rounded-lg cursor-pointer mx-0.5" (click)="goToSignedDevis()">ici</a> pour y accéder.    
        <a (click)="hideSnackBar()" class="ml-4 text-green-300 cursor-pointer">Fermer</a>
    </p>
    <p class="text-white" *ngIf="this.type== 'AH'">
        Le statut de l'attestation sur l'honneur du client {{customer.fullName}} vient d'évoluer, cliquer 
        <a class="text-white p-1 hover:bg-accent border-2 border-accent rounded-lg cursor-pointer mx-0.5" (click)="goToSignedDocument()">ici</a> pour y accéder.    
        <a (click)="hideSnackBar()" class="ml-4 text-green-300 cursor-pointer">Fermer</a>
    </p>
    <p class="text-white" *ngIf="this.type== 'MANDAT'">
        Le statut du mandat du client {{customer.fullName}} vient d'évoluer, cliquer 
        <a class="text-white p-1 hover:bg-accent border-2 border-accent rounded-lg cursor-pointer mx-0.5" (click)="goToSignedDocument()">ici</a> pour y accéder.    
        <a (click)="hideSnackBar()" class="ml-4 text-green-300 cursor-pointer">Fermer</a>
    </p>
</ng-container>
