import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserProfile } from 'app/modules/models/profile-management/userProfile.type';
import { environment } from 'environments/environment';
import { map, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  protected apiRoute =  environment.RESOURCE_API;

  private _selectedRole:  ReplaySubject<UserProfile> = new ReplaySubject<UserProfile>(1); 
  
  constructor(private _http: HttpClient) {    
  }
  
  get selectedRole$(): Observable<UserProfile>
  {
      return this._selectedRole.asObservable();
  }
  
  set selectedRole(value: UserProfile)
  {
      // Store the value
      this._selectedRole.next(value);
  }

  getRoles(): Observable<UserProfile[]> {
    const route = this.apiRoute + environment.apiCatalog.role.subRoutes.getAll;
    return this._http.get(route, this.prepareHeader()).pipe(map(res => res as UserProfile[]))
  }

  getRolesByCompanyId(companyId: string, isCommon: boolean): Observable<UserProfile[]> {
    const route = this.apiRoute + environment.apiCatalog.role.subRoutes.getAllActive + '/' + companyId + '/' + isCommon;
    return this._http.get(route, this.prepareHeader()).pipe(map(res => res as UserProfile[]))
  }

  add(role: UserProfile): Observable<UserProfile> {
    const route = this.apiRoute + environment.apiCatalog.role.controllerRoute;
    return this._http.post(route, role, this.prepareHeader()).pipe(map(res => res as UserProfile))
  }

  addRange(roles: UserProfile[]): Observable<UserProfile[]> {
    const route = this.apiRoute + environment.apiCatalog.role.subRoutes.addMultiple;
    return this._http.post(route, roles, this.prepareHeader()).pipe(map(res => res as UserProfile[]))
  }

  edit(role: UserProfile): Observable<UserProfile> {
    const route = this.apiRoute + environment.apiCatalog.role.subRoutes.update;
    return this._http.put(route, role, this.prepareHeader()).pipe(map(res => res as UserProfile))
  }

  remove(role: UserProfile): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const route = this.apiRoute + environment.apiCatalog.role.subRoutes.remove;
    return this._http.delete(route, {headers:headers, body: role});
  }

  private prepareHeader(): Object {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return { headers: headers };
  }
}