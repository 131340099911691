import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HealthService {
    protected apiRoute = environment.RESOURCE_API;
    constructor(private http: HttpClient) { }

    getHealth(): Observable<any> {
        const url = this.apiRoute + 'health';
        return this.http.get(url);
    }
}