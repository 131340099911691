import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import { ErrorCatchingService } from '../services/error-catching.service';

@Injectable({
  providedIn: 'root',
})


export class ErrorCatchingInterceptor implements HttpInterceptor {

    constructor(private _notify: ErrorCatchingService) {
    }
 
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let SicaAPI = request.url.toLowerCase();
        return next.handle(request)
            .pipe(
                map(res => {
                    return res
                }),
                catchError((error: HttpErrorResponse) => {
                  let errorMessage = '';
                   if (error.status === undefined) {
                    // ? in case of http request not firing
                    errorMessage = 'Misconfigured HTTP request';
                    this._notify.notify = errorMessage
                    return throwError(errorMessage);
                  } 
                  let errorCode = error.status;
                  this._notify.notify = this.handleError(error, errorMessage, SicaAPI, errorCode) ;
                  return throwError(error);
                }),
            )
    }
    private handleError(error: HttpErrorResponse, errorMessage: string, SicaAPI: string, errorCode: number) {
      if (error.status === 0) {
        errorMessage = `Unable to establish connection with the backend server with error code:  ${error.status}\n ${error.error}`;
      }
      if (error.status === 502) {
        errorMessage = `The backend server is unreachable with error code:  ${error.status} \n ${error.error}`;
      } else if (error.status === 401) {
        errorMessage = `Unauthorized access to backend server with error code:  ${error.status} \n ${error.error}`;
      } else if (error.status === 404) {
        errorMessage = ` Unable to establish connection with the backend server with error code:  ${error.status} \n ${error.error}`;
      } else if (error.status === 500 ) {
        errorMessage = `Unknown backend server error with code:  ${error.status} \n ${error.error}`;
      } else if (typeof error.error === 'string') {
        errorMessage = error.error;
      } else if (error.error?.message) {
        errorMessage = error.error.message;
        errorCode = error.error.code ? error.error.code : errorCode;
      } else if (error.error?.errors) {
        // ? identity api
        const errorMessages = Object.values(error.error.errors);
        errorMessage = errorMessages.join('\r\n');
        errorCode = error.status ? error.status : errorCode;
      }
      return { errorMessage, error };
    }
}