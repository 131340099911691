import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { Company } from 'app/modules/models/company.type';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    protected apiRoute = environment.RESOURCE_API;

    private _connectedUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private _selectedUser: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _reload: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _isOnboarding: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _selectedCompany: ReplaySubject<Company> = new ReplaySubject<Company>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set connectedUser(value: User) {
        // Store the value
        this._connectedUser.next(value);
    }

    get connectedUser$(): Observable<User> {
        return this._connectedUser.asObservable();
    }

    get selectedUser$(): Observable<User> {
        return this._selectedUser.asObservable();
    }

    set selectedUser(value: User) {
        // Store the value
        this._selectedUser.next(value);
    }

    get selectedCompany$(): Observable<Company> {
        return this._selectedCompany.asObservable();
    }

    set selectedCompany(value: Company) {
        // Store the value
        this._selectedCompany.next(value);
    }

    set reload(value: boolean) {
        this._reload.next(value);
    }

    get reload$(): Observable<boolean> {
        return this._reload.asObservable();
    }

    set isOnboarding(value: boolean) {
        this._isOnboarding.next(value);
    }

    get isOnboarding$(): Observable<boolean> {
        return this._isOnboarding.asObservable();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(email: string): Observable<User> {
        let route = environment.RESOURCE_API + environment.apiCatalog.user.controllerRoute + email;
        return this._httpClient.get<User>(route).pipe(
            tap((user) => {
                this._connectedUser.next(user);
            })
        );
    }

    getUser(email: string): Observable<User> {
        let route = environment.RESOURCE_API + environment.apiCatalog.user.controllerRoute + email;
        return this._httpClient.get<User>(route, this.prepareHeader());
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        let route = this.apiRoute + environment.apiCatalog.user.subRoutes.update;
        return this._httpClient.put<User>(route, user, this.prepareHeader()).pipe(
            map((response) => {
                this._connectedUser.next(response);
            })
        );
    }

    activate(user: User): Observable<any> {
        let route = this.apiRoute + environment.apiCatalog.user.subRoutes.activate;
        return this._httpClient.post<User>(route, user, this.prepareHeader()).pipe(
            map((response) => {
                this._connectedUser.next(response);
            })
        );
    }

    getById(id: string): Observable<User> {
        const route = this.apiRoute + environment.apiCatalog.user.controllerRoute + '/' + id;
        return this._httpClient.get<User>(route, this.prepareHeader());
    }

    getUsers(): Observable<User[]> {
        const route = this.apiRoute + environment.apiCatalog.user.subRoutes.getAll;
        return this._httpClient.get<User[]>(route, this.prepareHeader());
    }

    getCompanyUsers(companyId: string): Observable<User[]> {
        const route = this.apiRoute + environment.apiCatalog.user.subRoutes.getAll + '/' + companyId;
        return this._httpClient.get<User[]>(route, this.prepareHeader());
    }

    getCountByCompanyId(companyId: string): Observable<number> {
        const route = this.apiRoute + environment.apiCatalog.user.subRoutes.getCount + '/' + companyId;
        return this._httpClient.get<number>(route, this.prepareHeader());
    }

    getCountAll(): Observable<number> {
        const route = this.apiRoute + environment.apiCatalog.user.subRoutes.getCountAllUsers;
        return this._httpClient.get<number>(route, this.prepareHeader());
    }

    public createUser(body: User): Observable<User> {
        const route = this.apiRoute + environment.apiCatalog.user.controllerRoute;
        return this._httpClient.post(route, body, this.prepareHeader()).pipe(map(res => res as any));
    }

    deleteUser(body: User): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        const route = this.apiRoute + environment.apiCatalog.user.subRoutes.delete;
        return this._httpClient.delete(route, { headers: headers, body: body }).pipe(map(res => res as any))
    }

    updateUser(user: User): Observable<any> {
        let route = this.apiRoute + environment.apiCatalog.user.subRoutes.update;
        return this._httpClient.put<User>(route, user, this.prepareHeader()).pipe(map(res => res as any))
    }

    getUsersByProfileId(profileId: string): Observable<User[]> {
        const route = this.apiRoute + environment.apiCatalog.user.subRoutes.getAll + '/' + profileId;
        return this._httpClient.get<User[]>(route, this.prepareHeader());
    }

    deactivateUser(user: User): Observable<any> {
        let route = this.apiRoute + environment.apiCatalog.user.subRoutes.deactivate;
        return this._httpClient.put<User>(route, user, this.prepareHeader()).pipe(map(res => res as any))
    }

    cloneDatabase(action : string): Observable<any> {
        const formData = new FormData();
        formData.append('action', action);
        let route = this.apiRoute + environment.apiCatalog.user.subRoutes.cloneDatabase+'?action=' + action;
      
        return this._httpClient.post<any>(route,  formData,this.prepareHeader()).pipe(map(res => res as any))

    }


    private prepareHeader(): Object {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        return { headers: headers };
    }
}
