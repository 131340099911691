<div >
    <div class="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-8 mt-8">
        <div class="grid grid-cols-1">
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                animation="progress" 
                class="w-full"
                [theme]="{ 'height': '190px' }">
            </ngx-skeleton-loader>
        </div>
        <div class="grid grid-rows-4">
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                animation="progress" 
                class="w-full"
                [theme]="{ 'height': '30px' }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                animation="progress" 
                class="w-full"
                [theme]="{ 'height': '30px' }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                animation="progress" 
                class="w-full"
                [theme]="{ 'height': '30px' }">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                animation="progress" 
                class="w-full"
                [theme]="{ 'height': '30px' }">
            </ngx-skeleton-loader>
        </div>
    </div>
    <div class="grid grid-rows-4 gap-4 mt-4">
        <ngx-skeleton-loader 
            count="1" 
            appearance="line" 
            animation="progress" 
            class="w-full"
            [theme]="{ 'height': '30px' }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader 
            count="1" 
            appearance="line" 
            animation="progress" 
            class="w-full"
            [theme]="{ 'height': '30px' }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader 
            count="1" 
            appearance="line" 
            animation="progress" 
            class="w-full"
            [theme]="{ 'height': '30px' }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader 
            count="1" 
            appearance="line" 
            animation="progress" 
            class="w-full"
            [theme]="{ 'height': '30px' }">
        </ngx-skeleton-loader>
    </div>
</div>