<div >
   
    <a class="flex justify-end px-8 mt-8 text-primary cursor-pointer hover:text-accent hover:underline"
        (click)="goToSupport()" [matTooltip]="' Envoie à la page Suivi des demandes de support'">
        <b>Accéder à vos demandes support</b>
    </a>
    <form class="flex-auto p-8" [formGroup]="supportRequestForm" class="p-8 mb-16">
         <mat-form-field appearance="fill" *ngIf="action === 'edit' && isSuperAdmin" class="w-full">
            <mat-label>Priorité</mat-label>
            <mat-select [formControlName]="'priority'">
                <mat-option *ngFor="let priority of priorities" [value]="priority">
                    {{priority}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="action === 'edit' && isSuperAdmin" class="w-full">
            <mat-label>Statut</mat-label>
            <mat-select [formControlName]="'status'" (selectionChange)="onStatusChange($event)">
                <mat-option *ngFor="let status of statuses" [value]="status">
                    {{status}}
                </mat-option>
            </mat-select>
        </mat-form-field> 

        <mat-form-field class="w-full" *ngIf="action === 'edit' && isSuperAdmin" class="w-full" > 
            <mat-label>Date de résolution</mat-label>
            <input matInput [matDatepicker]="dp2" [formControlName]="'resolutionDate'" [disabled]="true">
            <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
            <mat-datepicker #dp2></mat-datepicker>
            <mat-hint class="text-xs">JJ/MM/AAAA</mat-hint>
        </mat-form-field> 
        
        <mat-form-field appearance="fill" class="w-full">
            <mat-label>Type de demande</mat-label>
            <mat-select [formControlName]="'type'" [disabled]="action === 'edit'">
                <mat-option *ngFor="let type of requestTypes" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Objet</mat-label>
            <input  matInput [formControlName]="'object'" [disabled]="action === 'edit'">
        </mat-form-field>
        <p class="font-inter text-[#576070] text-[14px] font-semibold h-[18px] leading-5  mb-2">Description</p>
        <quill-editor class="mt-8" [styles]="{height: 'calc(20vh - 75px)'}"
          placeholder="Insérez du texte ici..." [formControlName]="'description'" [bounds]="'self'"
          [modules]="quillModules" [readOnly]="action === 'edit'">
        </quill-editor>
        <div *ngIf= "action === 'add'" >
        <p class="mt-8 cursor-pointer text-accent hover:underline" (click)="fileInput.click()">
              <b>Insérer des pièces jointes</b>
        </p>
        <input #fileInput type="file" [multiple]="true" style="display: none;" (change)="handleFilesInput($event)">
        <!-- loader -->
        <ng-container *ngIf="isLoadingFiles">
          <div class="grid grid-cols-2 gap-8 p-8 mt-8">
              <app-file-thumbnail-loader-skeleton></app-file-thumbnail-loader-skeleton>
              <app-file-thumbnail-loader-skeleton></app-file-thumbnail-loader-skeleton>
              <app-file-thumbnail-loader-skeleton></app-file-thumbnail-loader-skeleton>
              <app-file-thumbnail-loader-skeleton></app-file-thumbnail-loader-skeleton>
          </div>
      </ng-container>
          <!-- file list -->
          <ng-container *ngIf="!isLoadingFiles && files.length > 0">
              <div class="grid grid-cols-2 gap-8 mt-8">
                  <div *ngFor="let item of files; trackBy: trackByFn"
                      class="relative grid w-56 h-56 grid-cols-1 p-4 mb-8 shadow rounded-2xl bg-card">
                      <button class="absolute z-20 top-2 right-1 pl-1 w-8 h-8 min-h-8" mat-menu-item (click)="deleteItemByNode(item)">
                          <span class="flex items-center">
                              <mat-icon class="icon-size-5 hover:text-warn" svgIcon="heroicons_outline:trash"></mat-icon>
                          </span>
                      </button>
                      <a class="absolute inset-0 flex flex-col p-4 cursor-pointer"
                          >
                          <div class="aspect-w-9 aspect-h-6">
                              <div class="flex items-center justify-center">
                                  <!-- Icon -->
                                  <div class="relative">
                                      <mat-icon class="icon-size-14 text-hint"
                                          [svgIcon]="'heroicons_outline:document'"></mat-icon>
                                      <div class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white "
                                          [ngClass]="{
                                                  'bg-red-600': item.mimetype === 'PDF',
                                                  'bg-red-800': item.mimetype === 'PPT',
                                                  'bg-green-600': item.mimetype === 'XLS',
                                                  'bg-blue-600': item.mimetype === 'DOC',
                                                  'bg-orange-600': item.mimetype === 'JPG' || item.mimetype === 'PNG' || item.mimetype === 'GIF' || item.mimetype === 'JPEG' || item.mimetype === 'WEBP' ,                                    
                                                  'bg-gray-600': item.mimetype === 'TXT',
                                                  'bg-black': item.mimetype === 'FILE'
                                                  }">
                                          {{item.mimetype}}
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="flex flex-col justify-center flex-auto text-sm font-medium text-center">
                              <div class="flex flex-col truncate" [matTooltip]="item.name">{{item.name}}</div>
                              <ng-container>
                                  <div class="flex w-full mt-1">
                                  </div>
                              </ng-container>
                          </div>
                      </a>
                  </div>
              </div>
          </ng-container>
        </div>

        <div *ngIf = "action === 'edit' && files.length > 0 " class="mt-6 ">
            <b  class="mb-6 cursor-pointer text-accent">Pièce(s) jointe(s) : </b>
            <p *ngFor="let file of files"  class="cursor-pointer text-accent hover:underline" (click)="clickDownloadSavedFile(file)">
                {{file.name}}
            </p>
        </div>

         
    </form>
    <div *ngIf="isSuperAdmin || action !== 'edit'" class="flex flex-row border-t border-solid gray-bottom-border object-contain absolute bottom-0 left-0 w-full bg-sable">
        <div class="grid w-full grid-cols-1 gap-8 p-3 sm:grid-cols-2 lg:grid-cols-2">
          <button mat-stroked-button (click)="toggleDrawer()">Annuler</button>
          <button mat-flat-button class="text-white bg-accent"
            (click)= "action === 'add' ? sendDemand() : editSupportRequest()" [disabled]="!supportRequestForm.valid">{{action === 'add' ? 'Envoyer' : 'Enregistrer'}}</button>
        </div>
    </div>
    <div *ngIf="!isSuperAdmin && action === 'edit'" class="flex flex-row border-t border-solid gray-bottom-border object-contain absolute bottom-0 left-0 w-full bg-sable">
        <div class="grid w-full grid-cols-1 gap-8 p-3 sm:grid-cols-1 lg:grid-cols-1">
          <button mat-stroked-button (click)="toggleDrawer()">Annuler</button>
        </div>
    </div>